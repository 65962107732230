
import React, {useState} from "react"

import TagexclusiveItemsForm from "../../components/negosyonow/forms/tagexclusiveitemsform"


const negosyonowAPI = require("../../../lib/requestNegosyownow")


const nnproductpickerfields = [
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Product", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"SRP", "dbfield": "nnproduct_price", "type": "currency", "filtertype": "currency"},
	{"label":"Last Updated", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
];

const nnproductpickerfilter = [
	{"field":"nnproduct_orgsku", "operation": "<>", "value": ""},
];

const subformFields = [
	{
		"subformid": "nnproductpricing",
		"label": "Products",
		"table": "nnproductpricing",
		"sort": "nnproductpricing_id",
		"mobilerowtitlefieldidx":[1,0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Seller SKU",
				"field": "nnproduct_orgsku",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nnproductpickerfields,
				"pickerfilters": nnproductpickerfilter

			},
			{
				"label": "Product",
				"field": "nnproduct_fullname",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly",
			},
			{
				"label": "SRP",
				"field": "nnproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly",
			},
			{
				"label": "Price",
				"field": "nnproductpricing_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
/*			{
				"label": "Difference",
				"field": "nnproduct_price-nnproductpricing_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			}
*/
		],
		"footerlist": [
		]
	},
];


const ProductexclusivepricingPage = ({location}) => {
	const [productidlist, setProductidlist] = useState([]);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		negosyonowAPI.buildEntityIdListbyParam({"idlist":productidlist, "mode": ""},token, "product").then(productresponse => {
			callback(productresponse);
		});
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var tmplist = [];
		var subformidx = 0;
		var rowidx = 0;
		var fieldidx = 0;
		var curproductid = 0;
		while (subformidx < subformlist.length) {

			rowidx = 0;
			while (rowidx < subformlist[subformidx].length) {
				// Get productid for the row
				fieldidx = 0;
				while (fieldidx < subformlist[subformidx][rowidx].length) {
					if (subformlist[subformidx][rowidx][fieldidx].field === "nnproduct_id") {
						curproductid = subformlist[subformidx][rowidx][fieldidx].value;
					}
					fieldidx++;
				}
				// check if row got modified/deleted, add productid
				fieldidx = 0;
				while (fieldidx < subformlist[subformidx][rowidx].length) {
					if (subformlist[subformidx][rowidx][fieldidx].modified === true) {
						tmplist.push(parseInt(curproductid, 10));
						break;
					}
					fieldidx++;
				}

				rowidx++;
			}
			subformidx++;
		}
		setProductidlist(tmplist);
		return {"status":"OK"};
	}

	function customEditFieldInfo(inputlist, idx)
	{
		// Don't allow editing of product once created
		const fieldlist = ["nnproduct_orgsku"];
		if (!fieldlist.includes(inputlist[idx].field)) {
			return inputlist[idx];
		}
		var tmpidx = 0;

		while (tmpidx < inputlist.length) {
			if (inputlist[tmpidx].field === "nnproductpricing_id") {
				if (inputlist[tmpidx].value !== "") {
					if (parseInt(inputlist[tmpidx].value) > 0) {
						var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
						tmpfield.mode = "readonly";
						return tmpfield;
					}
				}
			}
			tmpidx++;
		}
		// New Entry
		return inputlist[idx];
	} // customEditFieldInfo

	return <TagexclusiveItemsForm
				location={location}
				editSubFormFields={subformFields}
				customSubmit={customSubmit}
				customValidateValues={customValidateValues}
				customEditFieldInfo={customEditFieldInfo}

			/>


}


export default ProductexclusivepricingPage;
